import React from 'react'
import styles from './CommonBanner.module.scss'
import { Grid, Typography } from '@mui/material'

export interface ICommonBanner {
    heading: string;
}
const CommonBanner = ({ heading }:ICommonBanner) => {
    return (
        <Grid className={styles.commonBanner}>
            <Typography variant='h4' fontSize={31} fontWeight={600}>{heading}</Typography>
        </Grid>
    )
}

export default CommonBanner