import React from 'react'
import styles from './Footer.module.scss'
import { Box, Grid, Typography } from '@mui/material'
import logo from '../../assest/images/THELA 1_layerstyle.svg'
import { FaFacebook, FaInstagram, FaPhoneAlt } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { FaMapMarkerAlt } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { menus } from '../../data/heading'
import { useNavigate } from 'react-router-dom';

const Footer = () => {
    const navigation = useNavigate()
    return (
        <Grid container className={styles.footerContainer}>
            <Grid item sm={3}>
                {/* <Typography sx={{ cursor: "pointer" }} variant='h4' fontSize={28} fontWeight={800} onClick={() => { navigation('/'); window.scrollTo(0, 0) }}>Precilix</Typography> */}
                <img src={logo} width={150} onClick={() => { navigation('/'); window.scrollTo(0, 0) }} style={{ cursor: "pointer" }}/>
                <Typography marginBlockStart={1} marginBlockEnd={3} lineHeight={1.8}>Dedicated to advancing healthcare through innovative solutions, reliability, and patient-centered care.</Typography>
                <Box display={"flex"}>
                    <FaFacebook fontSize={25} />
                    <FaInstagram fontSize={25} style={{ marginInline: 18 }} />
                    <FaXTwitter fontSize={25} />
                </Box>
            </Grid>
            <Grid item sm={3}>
                <Typography variant='h5' fontSize={18} fontWeight={600}>Contact</Typography>
                <Box display={"flex"} marginBlock={2}>
                    <FaPhoneAlt fontSize={18} />
                    <Typography sx={{ marginInlineStart: 2 }}>+91-9746479999</Typography>
                </Box>
                <Box display={"flex"} marginBlock={2}>
                    <FaMapMarkerAlt fontSize={41} />
                    <Typography sx={{ marginInlineStart: 3 }}>Precilix, Raunak Bhavan, Market Junction, Manacaud, Thiruvananthapuram P.O, Kerala, India - 695009</Typography>
                </Box>
                <Box display={"flex"} marginBlock={2}>
                    <MdEmail fontSize={21} />
                    <Typography sx={{ marginInlineStart: 2 }}>contactus@precilix.com</Typography>
                </Box>
            </Grid>
            <Grid item sm={3}>
                <Typography variant='h5' fontSize={18} fontWeight={600}>Quick Links</Typography>
                {menus.map((item) => {
                    return (
                        <Typography onClick={() => { navigation(item.path); window.scrollTo(0, 0) }} marginBlock={2} sx={{ cursor: "pointer" }}>{item.label}</Typography>
                    )
                })}
            </Grid>
        </Grid>
    )
}

export default Footer