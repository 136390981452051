import React from 'react'
import styles from './Contact.module.scss'
import { Box, Grid, Typography } from '@mui/material'
import { FaPhoneAlt, FaMapMarkerAlt, FaFacebookF, FaInstagram, FaLinkedinIn } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { FaXTwitter } from "react-icons/fa6";
import ContactForm from '../../components/ContactForm/ContactForm';

const Contact = () => {
    return (
        <Grid container className={styles.contactContainer}>
            <Grid>
                <Typography variant='h4' fontSize={31} fontWeight={600} > Contact Us</Typography>
                <Typography marginBlockStart={2} marginBlockEnd={4}>Our dedicated team is available 24/7 to assist you with any inquiries regarding our products and services. Whether you're a healthcare professional, distributor, or prospective partner, we look forward to hearing from you.</Typography>
                <Box marginBlock={1} display={"flex"} gap="10px">
                    <FaPhoneAlt fontSize={21} style={{ color: "#44B09B" }} />
                    <Typography fontWeight={600} sx={{ width: "85%" }}>Phone: <span style={{ fontWeight: 500, }}>+91-9746479999</span></Typography>
                </Box>
                <Box marginBlock={1} display={"flex"} gap="10px">
                    <MdEmail fontSize={21} style={{ color: "#44B09B" }} />
                    <Typography fontWeight={600} sx={{ width: "85%" }}>Email: <span style={{ fontWeight: 500, }}>contactus@precilix.com</span></Typography>
                </Box>
                <Box marginBlock={1} display={"flex"} gap="10px">
                    <FaMapMarkerAlt fontSize={21} style={{ color: "#44B09B" }} />
                    <Typography fontWeight={600} sx={{ width: "85%" }}>Address: <span style={{ fontWeight: 500, }}>Precilix, Raunak Bhavan, Market Junction, Manacaud, Thiruvananthapuram P.O, Kerala, India - 695009</span></Typography>
                </Box>
                <Box>
                    {/* <FaFacebookF fontSize={28} />
                    <FaInstagram fontSize={28} style={{ marginInline: 8 }} />
                    <FaXTwitter fontSize={28} style={{ marginInlineEnd: 8 }} />
                    <FaLinkedinIn fontSize={28} /> */}
                </Box>
            </Grid>
            <Grid>
                <ContactForm />
            </Grid>
        </Grid>
    )
}

export default Contact