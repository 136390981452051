import client_1 from '../assest/brand1.svg'
import client_2 from '../assest/brand1.svg'
import client_3 from '../assest/brand1.svg'
import client_4 from '../assest/brand1.svg'
import client_5 from '../assest/brand1.svg'
import client_6 from '../assest/brand1.svg'
import client_7 from '../assest/brand1.svg'
import client_8 from '../assest/brand1.svg'
import client_9 from '../assest/brand1.svg'
import trust_1 from '../assest/value1.svg'
import trust_2 from '../assest/value2.svg'
import trust_3 from '../assest/value3.svg'
import trust_4 from '../assest/value4.svg'
import trust_5 from '../assest/value5.svg'
import trust_6 from '../assest/value6.svg'
import product from '../assest/images/5.png'
import product_1 from '../assest/images/6.png'
import product_2 from '../assest/images/7.png'
import product_3 from '../assest/images/8.png'
import fetureProduct from '../assest/images/14.png'
import fetureProduct_1 from '../assest/images/15.png'
import fetureProduct_2 from '../assest/images/16.png'
import fetureProduct_3 from '../assest/images/17.png'


export const ourClients = [client_1, client_2, client_3, client_4, client_5, client_6, client_7, client_8, client_9]
export const trustHold = [trust_1, trust_2, trust_3, trust_4, trust_5, trust_6]
export const productCategories = [
    {
        "id": 1,
        "image": product,
        "name": "Pharma C&F Business",
        "desc": "Lorem ipsum dolor sit amet consectetur adipisicing elit. Laboriosam, in!"
    },
    {
        "id": 2,
        "image": product_1,
        "name": "Molecular Biology",
        "desc": "Lorem ipsum dolor sit amet consectetur adipisicing elit. Laboriosam, in!"
    },
    {
        "id": 3,
        "image": product_2,
        "name": "Molecular diagnostics lab",
        "desc": "Lorem ipsum dolor sit amet consectetur adipisicing elit. Laboriosam, in!"
    },
    {
        "id": 4,
        "image": product_3,
        "name": "R&D",
        "desc": "Lorem ipsum dolor sit amet consectetur adipisicing elit. Laboriosam, in!"
    }
]
export const productData = [
    {
        "id": 1,
        "image": product,
        "name": "Pharma logistics",
        "desc": "Optimizing pharmaceutical supply chains for efficient and reliable distribution"
    },
    {
        "id": 2,
        "image": product_1,
        "name": "Molecular biology",
        "desc": "Study of biological activities at the molecular level, including DNA"
    },
    {
        "id": 3,
        "image": product_2,
        "name": "Molecular diagnostics",
        "desc": "Cutting-edge molecular diagnostics for accurate disease detection and personalized treatment"
    },
    {
        "id": 4,
        "image": product_3,
        "name": "Research and Development",
        "desc": "Innovating healthcare through dedicated research and development initiative"
    }
]
export const fetureProductData = [
    {
        "id": 1,
        "image": fetureProduct,
        "name": "Pharma logistics",
        "desc": "Optimizing pharmaceutical supply chains for efficient and reliable distribution"
    },
    {
        "id": 2,
        "image": fetureProduct_1,
        "name": "Molecular biology",
        "desc": "Study of biological activities at the molecular level, including DNA"
    },
    {
        "id": 3,
        "image": fetureProduct_2,
        "name": "Molecular diagnostics",
        "desc": "Cutting-edge molecular diagnostics for accurate disease detection and personalized treatment"
    },
    {
        "id": 4,
        "image": fetureProduct_3,
        "name": "Research and Development",
        "desc": "Innovating healthcare through dedicated research and development initiative"
    }
]
export const molecularBiology = [
    {
        "id": 1,
        "image": product,
        "name": "DNA EXTRACTION ",
        "desc": "Process of isolating DNA from cells, tissues, or organisms for molecular biology research or diagnostics."
    },
    {
        "id": 2,
        "image": product_1,
        "name": "RNA EXTRACTION",
        "desc": "RNA extraction is the process of isolating RNA molecules from cells or tissues for molecular biology analysis."
    },
    {
        "id": 3,
        "image": product_2,
        "name": "PLASMID DNA PURIFICATION",
        "desc": "Technique to isolate and purify plasmid DNA from bacterial cells for various molecular biology applications."
    },
    {
        "id": 4,
        "image": product_3,
        "name": "PCR/GEL PURIFICATION",
        "desc": "PCR/gel purification involves isolating and purifying specific DNA fragments from PCR products or agarose gels for analysis."
    },
    {
        "id": 5,
        "image": product,
        "name": "PCR",
        "desc": "PCR (Polymerase Chain Reaction) amplifies DNA segments for research, diagnosis, and genetic testing purposes."
    },
    {
        "id": 6,
        "image": product_1,
        "name": "REAL TIME PCR",
        "desc": "Real-time PCR monitors amplification of DNA in real-time, enabling quantification and analysis of gene expression."
    },
    {
        "id": 7,
        "image": product_2,
        "name": "REVERSE TRANSCRIPTION PCR",
        "desc": "Reverse Transcription PCR synthesizes cDNA from RNA templates, enabling gene expression analysis and viral detection."
    },
    {
        "id": 8,
        "image": product_3,
        "name": "PRIMERS, dNTP, & ENZYMES",
        "desc": "Primers initiate DNA synthesis, dNTPs are building blocks, and enzymes catalyze reactions in PCR and molecular biology."
    },
    {
        "id": 9,
        "image": product_3,
        "name": "DNA MOLECULAR WEIGHT STANDARDS",
        "desc": "Lorem ipsum dolor sit amet consectetur adipisicing elit. Laboriosam, in!"
    }
]
export const tesimonialData = [
    {
        "id": 1,
        "image": product,
        "name": "John Deo",
        "desc": "PharmaLogix's efficient handling of sensitive deliveries ensures our patients receive timely medications. Their professionalism and dedication to maintaining the cold chain are unmatched. We are extremely satisfied with their service."
    },
    {
        "id": 1,
        "image": product_1,
        "name": "Sherry Lin",
        "desc": "PharmaLogix's advanced tracking ensures secure, timely shipments. Their responsive, expert team has streamlined our supply chain operations. They've set a high standard in pharmaceutical logistics. Highly recommended."
    },
    {
        "id": 1,
        "image": product_2,
        "name": "Davit Smith",
        "desc": "Timely and safe medication delivery is crucial, and PharmaLogix consistently exceeds expectations with reliable, efficient logistics. Their quality and customer commitment make them a truly dependable partner. Highly recommended."
    }
]
export const teamData = [
    {
        "id": 1,
        "image": product,
        "name": "John Deo",
        "designation": "Pharmaceutical Sales & Marketing"
    },
    {
        "id": 1,
        "image": product_1,
        "name": "John Deo",
        "designation": "Pharma C&F  operations"
    },
    {
        "id": 1,
        "image": product_2,
        "name": "John Deo",
        "designation": "Medical Research"
    },
    {
        "id": 1,
        "image": product_3,
        "name": "John Deo",
        "designation": "Diagnostics"
    }
]
export const newsData = [
    {
        "id": 1,
        "image": product,
        "heading": "Hands on Training Program on Molecular Disease Diagnostics in Aquatic Animal Health",
        "desc": "Hands on Training Program on Molecular Disease Diagnostics in Aquatic Animal Health Sponsored by Origin. Register by scanning the QR code on the flyer. Hands on Training Program on Molecular Disease Diagnostics in Aquatic Animal Health Sponsored by Origin. Register by scanning the QR code on the flyer.Hands on Training Program on Molecular Disease Diagnostics in Aquatic Animal Health Sponsored by Origin. Register by scanning the QR code on the flyer."
    },
    {
        "id": 1,
        "image": product_1,
        "heading": "Hands on Training Program on Molecular Disease Diagnostics in Aquatic Animal Health",
        "desc": "Hands on Training Program on Molecular Disease Diagnostics in Aquatic Animal Health Sponsored by Origin. Register by scanning the QR code on the flyer. Hands on Training Program on Molecular Disease Diagnostics in Aquatic Animal Health Sponsored by Origin. Register by scanning the QR code on the flyer.Hands on Training Program on Molecular Disease Diagnostics in Aquatic Animal Health Sponsored by Origin. Register by scanning the QR code on the flyer."
    },
    {
        "id": 1,
        "image": product_2,
        "heading": "Hands on Training Program on Molecular Disease Diagnostics in Aquatic Animal Health",
        "desc": "Hands on Training Program on Molecular Disease Diagnostics in Aquatic Animal Health Sponsored by Origin. Register by scanning the QR code on the flyer. Hands on Training Program on Molecular Disease Diagnostics in Aquatic Animal Health Sponsored by Origin. Register by scanning the QR code on the flyer.Hands on Training Program on Molecular Disease Diagnostics in Aquatic Animal Health Sponsored by Origin. Register by scanning the QR code on the flyer."
    },
    {
        "id": 1,
        "image": product_3,
        "heading": "Hands on Training Program on Molecular Disease Diagnostics in Aquatic Animal Health",
        "desc": "Hands on Training Program on Molecular Disease Diagnostics in Aquatic Animal Health Sponsored by Origin. Register by scanning the QR code on the flyer. Hands on Training Program on Molecular Disease Diagnostics in Aquatic Animal Health Sponsored by Origin. Register by scanning the QR code on the flyer.Hands on Training Program on Molecular Disease Diagnostics in Aquatic Animal Health Sponsored by Origin. Register by scanning the QR code on the flyer."
    }
]