import React, { useEffect, useState, useRef } from 'react'
import styles from './Jobs.module.scss'
import { Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import axios from 'axios'
import { baseurl } from '../../baseurl/baseurl'


const Jobs = () => {
    const [jobs, setJobs] = useState<any>({})
    const getJobsData = async () => {
        const response = await axios.get(`${baseurl}/job`)
        const data = response?.data?.jobs
        setJobs(data)
    }

    useEffect(() => {
        getJobsData()

    }, [])
    return (
        <Grid>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow sx={{ backgroundColor: "#fbfbfb" }}>
                            <TableCell>NAME</TableCell>
                            <TableCell>PHONE</TableCell>
                            <TableCell>EMAIL</TableCell>
                            <TableCell>QUALIFICATION</TableCell>
                            <TableCell>APPLY FOR</TableCell>
                            <TableCell>RESUME</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {jobs.length > 0 && jobs.map((item: any) => {
                            return (
                                <TableRow>
                                    <TableCell>{item.name}</TableCell>
                                    <TableCell>{item.phone}</TableCell>
                                    <TableCell>{item.email}</TableCell>
                                    <TableCell>{item.qualification}</TableCell>
                                    <TableCell>{item.apply_for}</TableCell>
                                    <TableCell sx={{cursor:"pointer"}} onClick={() => { window.open(item.resume, '_blank') }}>Download</TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </Grid>
    )
}

export default Jobs