import React from 'react'
import styles from './CompanyOverview.module.scss'
import { Grid } from '@mui/material'
import HeadingText from '../headingText/HeadingText'
import img from '../../assest/images/15.png'
import CommonButton from '../commonButton/CommonButton'
import { useNavigate } from 'react-router-dom'

const CompanyOverview = () => {
    const navigation = useNavigate()
    return (
        <Grid container className={styles.companyOverview}>
            <Grid item sm={7}>
                <HeadingText
                    heading={'Precilix: Advancing Healthcare Through Precision'}
                    span={''}
                    desc="At Precilix, we're a team dedicated to accelerating scientific breakthroughs and improving patient lives. We combine our expertise in four key areas:"
                />
                <ul>
                    <li>Pharmaceutical Logistics: Our seasoned team ensures the efficient and secure delivery of critical pharmaceuticals, so they reach those who need them most.</li>
                    <li>Molecular Reagents: We develop and supply high-quality molecular reagents, the building blocks for accurate diagnoses and research.</li>
                    <li>Molecular Diagnostics: We offer advanced molecular diagnostic solutions, empowering researchers and clinicians with precise tools for early detection and treatment decisions.</li>
                    <li>R&D: Our passionate research and development team is constantly innovating, pushing the boundaries of what's possible in healthcare.</li>
                </ul>
                <CommonButton
                    name={'Contact us'}
                    color={'#ffffff'}
                    bgColor={'#1D2B35'}
                    handleClick={() => { navigation('/Contact'); window.scrollTo(0, 0) }} />
            </Grid>
            <Grid item sm={5}>
                <img src={img} />
            </Grid>
        </Grid>
    )
}

export default CompanyOverview