export const menus =[
    {
        "id":1,
        "label":"Home",
        "path":"/"
    },
    {
        "id":1,
        "label":"About Us",
        "path":"/about"
    },
    {
        "id":1,
        "label":"Products",
        "path":"/product"
    },
    {
        "id":1,
        "label":"Career",
        "path":"/career"
    },
    {
        "id":1,
        "label":"Contact Us",
        "path":"/contact"
    }
]