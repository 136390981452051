import React, { useState } from 'react'
import styles from './Heading.module.scss'
import { Grid, List, ListItemButton, ListItemText, Typography } from '@mui/material'
// import logo from '../../assest/images/Precilix_logo_colour_dark_bg-removebg-preview 1.svg'
import logo from '../../assest/images/tempImageGAGfAe 1_layerstyle.svg'

import { menus } from '../../data/heading'
import CommonButton from '../commonButton/CommonButton'
import { useNavigate } from 'react-router-dom'
import { IoReorderThreeSharp } from "react-icons/io5";


const Heading = () => {
    const navigation = useNavigate()
    const [show, setShow] = useState(false)
    return (
        <Grid container className={styles.headingContainer}>
            <Grid item sm={6}>
                <img src={logo} width={150} onClick={() => { navigation('/'); window.scrollTo(0, 0) }} style={{ cursor: "pointer" }} />
            </Grid>
            <Grid item sm={6}>
                {window.innerWidth > 480 ?
                    <>{
                        menus.map((item) => {
                            return (
                                <Typography onClick={() => navigation(item.path)}>{item.label}</Typography>
                            )
                        })
                    }</>
                    :
                    <IoReorderThreeSharp fontSize={41} style={{ color: "#ffffff" }} onClick={() => setShow(!show)} />
                }
                {show &&
                    <Grid className={styles.mobileMenu}>
                        <List>
                            {menus.map((item) => {
                                return (
                                    <ListItemButton onClick={() => { navigation(item.path); setShow(false) }}>
                                        <ListItemText>{item.label}</ListItemText>
                                    </ListItemButton>
                                )
                            })}
                        </List>

                    </Grid>
                }

                {/* <CommonButton
                    name={'Get in Touch'}
                    color={'#141476'}
                    bgColor={'#ffffff'}
                    handleClick={() => navigation('/')} /> */}
            </Grid>
        </Grid>
    )
}

export default Heading