import React from 'react'
import styles from './ProductCategories.module.scss'
import { Box, Grid, Typography } from '@mui/material'
import HeadingText from '../headingText/HeadingText'
import { FaStop } from 'react-icons/fa'
import { productCategories } from '../../data/home'
import Product from '../Product/Product'
import CommonButton from '../commonButton/CommonButton'
import img from '../../assest/images/10.png'
import img_1 from '../../assest/images/11.png'
import img_2 from '../../assest/images/12.png'
import img_3 from '../../assest/images/13.png'
import { useNavigate } from 'react-router-dom'

export interface IProductCategories {
    handleClick: () => void;
}
const ProductCategories = ({ handleClick }: IProductCategories) => {
    const navigation = useNavigate()
    return (
        <Grid className={styles.productCategoriesContainer}>
            <HeadingText
                isLabel={false}
                icon={<FaStop style={{ color: "#44AE99" }} />}
                label='Top'
                heading={'Product '}
                span={'Categories'}
                desc='Explore our extensive array of product categories tailored to meet diverse healthcare needs and specific requirements with meticulous attention and expertise. Discover solutions designed to deliver precision and care across a spectrum of specialized healthcare applications.'
            />
            <Grid className={styles.productCategories}>
                <Grid className={styles.categories}>
                    <img src={img} />
                    <Box>
                        <Typography variant='h4' fontSize={16} fontWeight={600}>Pharma C&F Business</Typography>
                        <Typography>Efficient pharmaceutical logistics ensuring timely deliveries.</Typography>
                        <CommonButton name={'Enquiry Now'} color={'#ffffff'} bgColor={'#1D2A35'} handleClick={handleClick} />
                    </Box>
                </Grid>
                <Grid className={styles.categories}>
                    <img src={img_1} />
                    <Box>
                        <Typography variant='h4' fontSize={16} fontWeight={600}>Molecular Biology</Typography>
                        <Typography>Study of biological processes at the molecular and genetic level.</Typography>
                        <Box>
                            <CommonButton name={'Enquiry Now'} color={'#ffffff'} bgColor={'#1D2A35'} handleClick={handleClick} />
                            <CommonButton name={'Read More'} color={'#ffffff'} bgColor={'#1D2A35'} handleClick={() => { navigation('/molecular-biology'); window.scrollTo(0, 0) }} />
                        </Box>
                    </Box>
                </Grid>
                <Grid className={styles.categories}>
                    <img src={img_2} />
                    <Box>
                        <Typography variant='h4' fontSize={16} fontWeight={600}>Molecular diagnostics lab</Typography>
                        <Typography>Laboratory specializing in identifying diseases at the molecular level accurately
                        </Typography>
                        <CommonButton name={'Enquiry Now'} color={'#ffffff'} bgColor={'#1D2A35'} handleClick={handleClick} />
                    </Box>
                </Grid>
                <Grid className={styles.categories}>
                    <img src={img_3} />
                    <Box>
                        <Typography variant='h4' fontSize={16} fontWeight={600}>R&D</Typography>
                        <Typography>Innovation, testing, and refinement of new products/services.</Typography>
                        <CommonButton name={'Enquiry Now'} color={'#ffffff'} bgColor={'#1D2A35'} handleClick={handleClick} />
                    </Box>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default ProductCategories