import React from 'react'
import styles from './Layout.module.scss'
import { Divider, Grid, ListItemButton, ListItemText, Typography } from '@mui/material'
import { menuData } from '../../data/menu'
import { Route, Routes, useNavigate } from 'react-router-dom'
import Enquiry from '../../pages/enquiry/Enquiry'
import Products from '../../pages/products/Products'
import Jobs from '../../pages/Jobs/Jobs'
import SubProducts from '../../pages/subProducts/SubProducts'
import FeaturedProduct from '../../pages/featuredProduct/FeaturedProduct'

const Layout = () => {
    const navigation = useNavigate()
    const handleLogout = ()=>{
        localStorage.removeItem("token")
        navigation('/login')
    }
    return (
        <Grid container className={styles.layoutContainer}>
            <Grid item sm={2} className={styles.leftSide}>
                <Typography sx={{ borderBlockEnd: "1px solid #444444", width: "fit-content", marginInline: 'auto', paddingBlock: 2 }} variant='h4' fontSize={28} fontWeight={800}>Precilix </Typography>
                <Divider sx={{ marginBlock: 2 }} />
                {menuData.map((item) => {
                    return (
                        <ListItemButton key={item.id} onClick={() => navigation(item.path)}>
                            <ListItemText >{item.label}</ListItemText>
                        </ListItemButton>
                    )
                })}
                <ListItemButton>
                    <ListItemText onClick={handleLogout}>Logout</ListItemText>
                </ListItemButton>
            </Grid>
            <Grid item sm={10} className={styles.rightSide}>
                <Routes>
                    <Route path='/enquiry' element={<Enquiry />} />
                    <Route path='/products' element={<Products />} />
                    <Route path='/featured-products' element={<FeaturedProduct />} />
                    <Route path='/sub-products' element={<SubProducts />} />
                    <Route path='/job-request' element={<Jobs />} />
                </Routes>
            </Grid>
        </Grid>
    )
}

export default Layout