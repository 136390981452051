import { Box, Divider, Grid, Typography } from '@mui/material'
import React, { useState } from 'react'
import CommonBanner from '../../components/CommonBanner/CommonBanner'
import styles from './Career.module.scss'
import InputField from '../../components/InputField/InputField'
import CommonButton from '../../components/commonButton/CommonButton'
import axios from 'axios'
import { toast } from 'react-toastify'
import { baseurl } from '../../baseurl/baseurl'

const Career = () => {
  const [inputVal, setInputVal] = useState({ name: "", email: "", phone: "", qualification: "", apply_for: "", resume: "" })


  const handleChange = (e: any) => {
    const { name, type, files, value } = e.target;

    if (type === 'file') {
      setInputVal({ ...inputVal, [name]: files[0] });
    } else {
      setInputVal({ ...inputVal, [name]: value });
    }
  };
  console.log(inputVal, "inputVal...")
  const handleSubmit = async () => {
    const formData = new FormData()
    formData.append("name", inputVal.name)
    formData.append("email", inputVal.email)
    formData.append("phone", inputVal.phone)
    formData.append("qualification", inputVal.qualification)
    formData.append("apply_for", inputVal.apply_for)
    formData.append("resume", inputVal.resume)
    try {
      const response = await axios.post(`${baseurl}/job/post`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      console.log(response, "response")
      if (response.status === 201) {
        toast.success(response.data.message)
        setInputVal({ name: "", email: "", phone: "", qualification: "", apply_for: "", resume: "" })
      }
    }
    catch (err) {
      console.log(err)
    }

  }
  return (
    <Grid className={styles.careerContainer}>
      <CommonBanner heading={'Career'} />
      <Grid className={styles.career}>
        <Typography variant='h5' fontSize={21} fontWeight={500}>Submit Your Resume</Typography>
        <Divider sx={{ marginBlock: 2 }} />
        <Grid className={styles.field}>
          <InputField label={'Name'} name={'name'} value={inputVal.name} onChange={handleChange} placeholder={''} />
          <InputField label={'Email'} name={'email'} value={inputVal.email} onChange={handleChange} placeholder={''} />
          <InputField label={'Applying For'} name={'apply_for'} value={inputVal.apply_for} onChange={handleChange} placeholder={''} />
          <InputField label={'Phone'} name={'phone'} value={inputVal.phone} onChange={handleChange} placeholder={''} />
          <InputField label={'Qualification'} name={'qualification'} value={inputVal.qualification} onChange={handleChange} placeholder={''} />
          <InputField label={'Resume'} name={'resume'} type={"file"} onChange={handleChange} placeholder={''} />
        </Grid>
        <Divider sx={{ marginBlock: 2 }} />
        <Grid className={styles.action}>
          <CommonButton name={'Submit'} color={'#ffffff'} bgColor={'#1D2C36'} handleClick={handleSubmit} />

        </Grid>
      </Grid>
    </Grid>
  )
}

export default Career