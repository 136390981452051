import React, { useEffect, useState } from 'react'
import styles from './FeaturedProduct.module.scss'
import { Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { productsData } from '../../data/products'
import { MdEdit, MdDelete } from "react-icons/md";
import { useNavigate } from 'react-router-dom';
import CommonButton from '../../components/commonButton/CommonButton';
import ProductModal from '../../components/modal/product/ProductModal';
import axios from 'axios';
import { baseurl } from '../../baseurl/baseurl';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const FeaturedProduct = () => {
    const navigation = useNavigate()
    const [open, setOpen] = useState(false)
    const [editModal, setEditModal] = useState(false)
    const handleModal = () => setOpen(!open)
    const handleClose = () => { setOpen(false); setEditModal(false) }
    const [inputVal, setInputVal] = useState({ name: "", desc: "", image: "" })
    const [productData, setProductData] = useState<any>({})
    const [productId, setProductId] = useState<any>();
    const [subProducts, SetSubProducts] = useState()
    const handleChange = (e: any) => {
        const { name, type, files, value } = e.target;

        if (type === 'file') {
            setInputVal({ ...inputVal, [name]: files[0] });
        } else {
            setInputVal({ ...inputVal, [name]: value });
        }
    };
    const getSubProductId = (idx: any) => {
        localStorage.setItem("productId", JSON.stringify(idx))

    }
    const getProductData = async () => {
        try {
            const response = await axios.get(`${baseurl}/featured-product`)
            if (response.status === 200) {
                setProductData(response.data.featuredProduct)
            }
        }
        catch (err) {
            console.log(err)
        }
    };
    const handleSubmit = async () => {
        const formData = new FormData()
        formData.append("name", inputVal.name)
        formData.append("desc", inputVal.desc);
        formData.append("image", inputVal.image);
        try {
            const response = await axios.post(`${baseurl}/featured-product/post`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            if (response.status === 201) {
                toast.success(response.data.message)
                setInputVal({ name: "", desc: "", image: "" })
                setOpen(false)
                getProductData()
            }
        }
        catch (err) {
            console.log(err)
        }

    };
    const handleEditModal = (idx: any) => {
        console.log(idx, "idx")
        setProductId(idx)
        setEditModal((preState: any) => ({ ...preState, [idx]: !preState[idx] }))
        const filteredProduct = productData.filter((item: any) => item._id === idx)
        setInputVal({
            name: filteredProduct[0].name,
            desc: filteredProduct[0].desc,
            image: filteredProduct[0].image
        })
    };
    const handleEdit = async () => {
        const formData = new FormData()
        formData.append("name", inputVal.name)
        formData.append("desc", inputVal.desc);
        formData.append("image", inputVal.image);
        try {
            const response = await axios.put(`${baseurl}/featured-product/update/${productId}`, formData)
            if (response.status === 200) {
                toast.success(response.data.message)
                setEditModal(false)
                getProductData();
            }
        }
        catch (err) {
            console.log(err)
        }
    };
    const handleDelete = async (productId: string | number) => {
        try {
            const response = await axios.delete(`${baseurl}/featured-product/delete/${productId}`)
            if (response.status === 201) {
                toast.success(response.data.message)
                getProductData();
            }
        }
        catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        getProductData()
    }, []);

    return (
        <Grid className={styles.productsContainer}>
            <Grid>
                <CommonButton name={'ADD PRODUCT'} handleClick={handleModal} color={''} bgColor={''} />
            </Grid>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow sx={{ backgroundColor: "#fbfbfb" }}>
                            <TableCell>PRODUCT</TableCell>
                            <TableCell>IMAGE</TableCell>
                            <TableCell>DESCRIPTION</TableCell>
                            <TableCell>SUB PRODUCT</TableCell>
                            <TableCell>ACTIONS</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {productData.length > 0 && productData.map((item: any) => {
                            return (
                                <TableRow>
                                    <TableCell>{item.name}</TableCell>
                                    <TableCell>
                                        <img src={item.image} width={50} />
                                    </TableCell>
                                    <TableCell>{item.desc}</TableCell>
                                    <TableCell onClick={() => { getSubProductId(item._id); navigation('/sub-products') }} sx={{ cursor: "pointer" }}>{"View"}</TableCell>
                                    <TableCell>
                                        <MdEdit fontSize={21} style={{ cursor: "pointer" }} onClick={() => handleEditModal(item._id)} />
                                        <MdDelete fontSize={21} style={{ cursor: "pointer" }} onClick={() => handleDelete(item._id)} />
                                    </TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <ProductModal
                open={open}
                heading={"Add Product"}
                handleClose={handleClose}
                inputVal={inputVal}
                handleChange={handleChange}
                handleSubmit={handleSubmit}
            />
            <ProductModal
                open={editModal}
                heading={"Edit Product"}
                handleClose={handleClose}
                inputVal={inputVal}
                handleChange={handleChange}
                handleSubmit={handleEdit}
            />
            <ToastContainer />
        </Grid>
    )
}

export default FeaturedProduct