import React, { useState } from 'react'
import styles from './ContactForm.module.scss'
import { Grid, TextField, Typography } from '@mui/material'
import CommonButton from '../commonButton/CommonButton'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { baseurl } from '../../baseurl/baseurl'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ContactForm = () => {
    const navigation = useNavigate()
    const [inputVal, setInputVal] = useState({ name: "", email: "", phone: "", message: "" })

    const handleChange = (e: any) => {
        const { name, value } = e.target;
        setInputVal({ ...inputVal, [name]: value })
    }
    const handleSubmit = async () => {
        if (inputVal.name === "" || inputVal.email === "" || inputVal.phone === "" || inputVal.message === "") {
            return toast.error("Please fill all the field")
        }

        try {
            const response = await axios.post(`${baseurl}/enquiry/create`, inputVal)
            if (response.status == 201) {
                toast.success(response.data.message)
                setInputVal({ name: "", email: "", phone: "", message: "" })
            }
        }
        catch (err) {
            console.log(err)
        }

    }
    return (
        <Grid className={styles.contactFormContainer}>
            <Typography marginBlockEnd={2} variant='h4' fontSize={21} fontWeight={500}>Contact Form</Typography>
            <Typography>Name</Typography>
            <TextField name='name' value={inputVal.name} onChange={handleChange} placeholder='Enter your name' />
            <Typography>Email</Typography>
            <TextField name='email' value={inputVal.email} onChange={handleChange} placeholder='Enter your email' />
            <Typography>Phone</Typography>
            <TextField name='phone' value={inputVal.phone} onChange={handleChange} placeholder='Enter your phone' />
            <Typography>Message</Typography>
            <TextField name='message' value={inputVal.message} onChange={handleChange} placeholder='Enter your message' />
            <CommonButton
                name={'Submit'}
                color={'#ffffff'}
                bgColor={'#1D2A35'}
                handleClick={handleSubmit}
            />
            <ToastContainer />
        </Grid>
    )
}

export default ContactForm