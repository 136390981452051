import React, { useEffect, useState } from 'react'
import styles from './SubProducts.module.scss'
import { Box, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { MdEdit, MdDelete } from 'react-icons/md'
import { productsData } from '../../data/products'
import CommonButton from '../../components/commonButton/CommonButton'
import axios from 'axios'
import { baseurl } from '../../baseurl/baseurl'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ProductModal from '../../components/modal/product/ProductModal'

const SubProducts = () => {
    const [open, setOpen] = useState(false)
    const [editModal, setEditModal] = useState(false)
    const handleModal = () => setOpen(!open)
    const handleClose = () => { setOpen(false); setEditModal(false) }
    const [inputVal, setInputVal] = useState({ name: "", desc: "", image: "" })
    const [productData, setProductData] = useState<any>({})
    const [productId, setProductId] = useState<any>();

    const handleChange = (e: any) => {
        const { name, type, files, value } = e.target;

        if (type === 'file') {
            setInputVal({ ...inputVal, [name]: files[0] });
        } else {
            setInputVal({ ...inputVal, [name]: value });
        }
    };

    const getSubProductData = async () => {
        const productIdStr: any = localStorage.getItem("productId");
        const selectedProductId = JSON.parse(productIdStr)
        try {
            const response = await axios.get(`${baseurl}/sub-product`)
            console.log(response, "response..")
            if (response.status === 200) {
                const data = response.data.subProduct
                console.log(data, "data..")
                const filterData = data.filter((item: any) => item?.productId?._id === selectedProductId)
                console.log(filterData, "filterData..")
                setProductData(filterData)
            }
        }
        catch (err) {
            console.log(err)
        }
    };
    const handleSubmit = async () => {
        const productIdStr: any = localStorage.getItem("productId");
        const selectedProductId = JSON.parse(productIdStr)

        const formData = new FormData()
        formData.append("name", inputVal.name)
        formData.append("desc", inputVal.desc);
        formData.append("image", inputVal.image);
        try {
            const response = await axios.post(`${baseurl}/sub-product/post/${selectedProductId}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            console.log(response, "response..")
            if (response.status === 201) {
                toast.success(response.data.message)
                setInputVal({ name: "", desc: "", image: "" })
                setOpen(false)
                getSubProductData()
            }
        }
        catch (err) {
            console.log(err)
        }

    };
    const handleEditModal = (idx: any) => {
        setProductId(idx)
        setEditModal((preState: any) => ({ ...preState, [idx]: !preState[idx] }))
        const filteredProduct = productData.filter((item: any) => item._id === idx)
        setInputVal({
            name: filteredProduct[0].name,
            desc: filteredProduct[0].desc,
            image: filteredProduct[0].image
        })
    };
    const handleEdit = async () => {
        const formData = new FormData()
        formData.append("name", inputVal.name)
        formData.append("desc", inputVal.desc);
        formData.append("image", inputVal.image);
        try {
            const response = await axios.put(`${baseurl}/sub-product/update/${productId}`, formData)
            if (response.status === 200) {
                toast.success(response.data.message)
                setEditModal(false)
                getSubProductData();
            }
        }
        catch (err) {
            console.log(err)
        }
    };
    const handleDelete = async (idx: string | number) => {
        try {
            const response = await axios.delete(`${baseurl}/sub-product/delete/${idx}`)
            if (response.status === 200) {
                toast.success(response.data.message)
                getSubProductData();
            }
        }
        catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        getSubProductData()
    }, [])

    return (
        <Grid className={styles.subProductsContainer}>
            <Box>
                <CommonButton name={'Add Sub Product'} handleClick={handleModal} color={'#ffffff'} bgColor={'#444444'} />
            </Box>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow sx={{ backgroundColor: "#fbfbfb" }}>
                            <TableCell>PRODUCT</TableCell>
                            <TableCell>IMAGE</TableCell>
                            <TableCell>DESCRIPTION</TableCell>
                            <TableCell>ACTIONS</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {productData && productData.length > 0 && productData.map((item: any) => {
                            return (
                                <TableRow>
                                    <TableCell>{item.name}</TableCell>
                                    <TableCell>
                                        <img src={item.img} width={50} />
                                    </TableCell>
                                    <TableCell>{item.desc}</TableCell>
                                    <TableCell>
                                        <MdEdit fontSize={21} style={{ cursor: "pointer" }} onClick={() => handleEditModal(item._id)} />
                                        <MdDelete fontSize={21} style={{ cursor: "pointer" }} onClick={() => handleDelete(item._id)} />
                                    </TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <ToastContainer />
            <ProductModal
                open={open}
                heading={'Add Sub Product'}
                inputVal={inputVal}
                handleChange={handleChange}
                handleSubmit={handleSubmit}
                handleClose={handleClose}
            />
            <ProductModal
                open={editModal}
                heading={'Edit Sub Product'}
                inputVal={inputVal}
                handleChange={handleChange}
                handleSubmit={handleEdit}
                handleClose={handleClose}
            />
        </Grid>
    )
}

export default SubProducts