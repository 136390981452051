import React from 'react'
import styles from './Product.module.scss'
import { Box, Grid, Typography } from '@mui/material'
import img from '../../assest/product.jpg'
import CommonButton from '../commonButton/CommonButton'
import { useNavigate } from 'react-router-dom'

export interface IProduct {
    img: string;
    name: string;
    desc: string;
    handleClick: any;
}
const Product = ({ img, name, desc, handleClick }: IProduct) => {
    const navigation = useNavigate()
    return (
        <Grid className={styles.productContainer}>
            <img src={img} />
            <Box>
                <Typography variant='h4' fontSize={16} fontWeight={600}>{name}</Typography>
                <Typography>{desc}</Typography>
                <CommonButton name={'Enquiry Now'} color={'#ffffff'} bgColor={'#1D2A35'} handleClick={handleClick} />
            </Box>
        </Grid>
    )
}

export default Product