import React from 'react'
import styles from './WhoWeAre.module.scss'
import { Grid, Typography } from '@mui/material'
import HeadingText from '../headingText/HeadingText'
import img from '../../assest/images/2.png'
import CommonButton from '../commonButton/CommonButton'
import { useNavigate } from 'react-router-dom'
import { RiMapPinUserFill } from "react-icons/ri";


const WhoWeAre = () => {
    const navigation = useNavigate()
    return (
        <Grid container className={styles.whoWeAreContainer}>
            <Grid item sm={7}>
                <HeadingText
                    isLabel={true}
                    icon={<RiMapPinUserFill fontSize={41} />}
                    label={'What we do'}
                    color="#ffffff"
                    heading={'Revolutionizing Healthcare Through Precision '}
                    span={'Solutions'}
                    desc={'We specialize in developing and implementing precision healthcare solutions, utilizing innovative technology to optimize patient care and outcomes.'} />
                <CommonButton
                    name={'Learn more'}
                    color={'#45B29C'}
                    bgColor={'#FFFFFF'}
                    handleClick={() => { navigation('/about'); window.scrollTo(0, 0) }}
                />
            </Grid>
            <Grid item sm={5}>
                <img src={img} />
            </Grid>
        </Grid>
    )
}

export default WhoWeAre