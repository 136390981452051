export const menuData = [
    {
        "id": 1,
        "icon": "",
        "label": "Enquiry",
        "path": "/enquiry"
    },
    {
        "id": 1,
        "icon": "",
        "label": "Products",
        "path": "/products"
    },
    {
        "id": 1,
        "icon": "",
        "label": "Job Request",
        "path": "job-request"
    }
]

