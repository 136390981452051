import React from 'react'
import styles from './Product.module.scss'
import { Grid } from '@mui/material'
import CommonBanner from '../../components/CommonBanner/CommonBanner'
import OurProducts from '../../components/OurProducts/OurProducts'
import ProductCategories from '../../components/ProductCategories/ProductCategories'
import ContactSection from '../../components/ContactSection/ContactSection'
import FetureProduct from '../../components/FetureProduct/FetureProduct'

export interface IProduct {
    handleClick: () => void;
}
const Product = ({ handleClick }: IProduct) => {
    return (
        <Grid className={styles.productContainer}>
            <CommonBanner heading={'Our Product'} />
            <ProductCategories handleClick={handleClick} />
            {/* <FetureProduct handleClick={handleClick} /> */}
            <ContactSection />
        </Grid>
    )
}

export default Product