import React from 'react'
import styles from './Login.module.scss'
import { Box, Grid, TextField, Typography } from '@mui/material'
import CommonButton from '../../components/commonButton/CommonButton'
export interface ILogin {
    inputVal: any;
    handleChange: any;
    handleLogin: any;
}
const Login = ({ inputVal, handleChange, handleLogin }: ILogin) => {
    return (
        <Grid className={styles.loginContainer}>
            <Grid className={styles.login}>
                <Box>
                    <Typography marginBlockEnd={3} variant='h4' fontSize={21} fontWeight={600}>Login Form</Typography>
                </Box>
                <Box>
                    <Typography>Email</Typography>
                    <TextField type='email' name='email' value={inputVal.email} onChange={handleChange} placeholder='Enter your email' />
                    <Typography>Password</Typography>
                    <TextField type='password' name='password' value={inputVal.password} onChange={handleChange} placeholder='Enter your password' />
                </Box>
                <CommonButton name={'Login'} handleClick={handleLogin} color={''} bgColor={''} />
            </Grid>
        </Grid>
    )
}

export default Login