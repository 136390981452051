import React from 'react'
import styles from './Banner.module.scss'
import { Grid, Typography } from '@mui/material'
import HeadingText from '../headingText/HeadingText'
import CommonButton from '../commonButton/CommonButton'
import { useNavigate } from 'react-router-dom'
import banner from '../../assest/images/9.png'

export interface IBanner{
    handleClick:()=>void
}
const Banner = ({handleClick}:IBanner) => {
    const navigaion = useNavigate()
    return (
        <Grid container className={styles.bannerContainer}>
            <Grid>
                <HeadingText
                    heading={'Welcome To '}
                    span={'Precilix'}
                    desc='Our company revolutionizes healthcare with precision medicine, delivering personalized treatments for improved patient outcomes and innovative medical solutions.' />
                <CommonButton
                    name={'Enquiry Now'} color={'#ffffff'} bgColor={'#1D2A35'} handleClick={handleClick}
                />
            </Grid>
            <Grid>
                <img src={banner} />
            </Grid>
        </Grid>
    )
}

export default Banner