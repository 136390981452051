import React, { useEffect, useState } from 'react'
import styles from './Enquiry.module.scss'
import { Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import axios from 'axios';
import { baseurl } from '../../baseurl/baseurl';

const Enquiry = () => {
    const [enquiry, setEnquiry] = useState<any>({});
    const getEnquiryData = async () => {
        try {
            const response = await axios.get(`${baseurl}/enquiry`)
            console.log(response, "response...")
            const data = response?.data?.enquiry
            setEnquiry(data)
        }
        catch (err) {
            console.log(err)
        }
    }
    useEffect(() => {
        getEnquiryData()
    }, [])
    return (
        <Grid className={styles.enquiryContainer}>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow sx={{ backgroundColor: "#fbfbfb" }}>
                            <TableCell>NAME</TableCell>
                            <TableCell>PHONE</TableCell>
                            <TableCell>EMAIL</TableCell>
                            <TableCell>MESSAGE</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {enquiry.length > 0 && enquiry.map((item: any) => {
                            return (
                                <TableRow>
                                    <TableCell>{item.name}</TableCell>
                                    <TableCell>{item.phone}</TableCell>
                                    <TableCell>{item.email}</TableCell>
                                    <TableCell>{item.message}</TableCell>
                                </TableRow>
                            )
                        })}

                    </TableBody>
                </Table>
            </TableContainer>
        </Grid>
    )
}

export default Enquiry