import React from 'react'
import styles from './News.module.scss'
import { Grid } from '@mui/material'
import CommonBanner from '../../components/CommonBanner/CommonBanner'
import NewsSection from '../../components/NewsSection/NewsSection'

const News = () => {
    return (
        <Grid>
            <CommonBanner heading={'News and Events'} />
            <NewsSection />
        </Grid>
    )
}

export default News