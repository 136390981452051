import React, { Fragment, useEffect, useState } from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'
import Layout from './components/layout/Layout'
import Login from './pages/login/Login'
import axios from 'axios'
import Home from './pages/home/Home'
import Heading from './components/heading/Heading'
import Footer from './components/footer/Footer'
import About from './pages/about/About'
import Contact from './pages/contact/Contact'
import News from './pages/news/News'
import Product from './pages/product/Product'
import Carrer from './pages/career/Career'
import MolecularBiology from './pages/MolecularBiology/MolecularBiology'
import QueryNow from './components/modal/QueryNow/QueryNow'
import { Grid } from '@mui/material'
import { baseurl } from './baseurl/baseurl'
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


const App = () => {
  const location = useLocation()
  const path = location.pathname
  console.log(path, "path")
  const [open, setOpen] = useState(false)
  const handleEnqueyModal = () => setOpen(!open)
  const handleClose = () => setOpen(false)
  const [isLogin, setIsLogin] = useState("")
  const [inputVal, setInputVal] = useState({ email: '', password: "" })
  const [formVal, setFormVal] = useState({ name: "", email: "", phone: "", message: "" })
  const handleChange = (e: any) => {
    const { name, value } = e.target
    setInputVal({ ...inputVal, [name]: value })
  }
  const handleChangeForm = (e: any) => {
    const { name, value } = e.target
    setFormVal({ ...formVal, [name]: value })
  }
  const handleLogin = async () => {
    try {
      const response = await axios.post(`${baseurl}/admin/login`, inputVal)
      console.log(response, "response...")
      if (response.status === 200) {
        toast.success(response.data.message)
        setInputVal({ email: '', password: "" })
        const token = response.data.token
        localStorage.setItem("token", JSON.stringify(token))
        setIsLogin(token)
      }
    }
    catch (err) {
      console.log(err)
    }
  }
  const handleSubmit = async () => {
    if (formVal.name === "" || formVal.email === "" || formVal.phone === "" || formVal.message === "") {
      return toast.error("Please fill all the field")
    }
    try {
      const response = await axios.post(`${baseurl}/enquiry/create`, formVal)
      console.log(response, "response")
      if (response.status === 201) {
        toast.success(response.data.message)
        setOpen(false)
        setFormVal({ name: "", email: "", phone: "", message: "" })
      }

    }
    catch (err) {
      console.log(err)
    }

  }
  useEffect(() => {
    const tokenStr: any = localStorage.getItem("token")
    const token = JSON.parse(tokenStr)
    setIsLogin(token)
  })

  return (
    <Fragment>
      {path === "/login" || path === '/enquiry' || path === '/products' || path === '/job-request' || path === "/sub-products" ?
        <>{isLogin ? <Layout /> :
          <Routes>
            <Route path='/login' element={<Login inputVal={inputVal} handleChange={handleChange} handleLogin={handleLogin} />} />
          </Routes>
        }</> :
        <><Heading />
          <Routes>
            <Route path='/' element={<Home handleClick={handleEnqueyModal} />} />
            <Route path='/About' element={<About />} />
            <Route path='/product' element={<Product handleClick={handleEnqueyModal} />} />
            <Route path='/molecular-biology' element={<MolecularBiology handleClick={handleEnqueyModal} />} />
            <Route path='/News' element={<News />} />
            <Route path='/Contact' element={<Contact />} />
            <Route path='/career' element={<Carrer />} />
            <Route path='/login' element={<Login inputVal={inputVal} handleChange={handleChange} handleLogin={handleLogin} />} />
          </Routes>
          <Footer />
        </>
      }

      <QueryNow
        open={open}
        inputVal={formVal}
        handleSubmit={handleSubmit}
        handleChange={handleChangeForm}
        handleClose={handleClose}
      />
      <ToastContainer />
    </Fragment>
  )
}

export default App