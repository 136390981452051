import React from 'react'
import styles from './NewsCard.module.scss'
import { Box, Grid, Typography } from '@mui/material'
import CommonButton from '../commonButton/CommonButton'
import { RiMapPinUserFill } from "react-icons/ri";
import { FaRegCalendarAlt } from "react-icons/fa";

export interface INewsCard {
    img: string;
    heading: string;
    desc: string;
}
const NewsCard = ({ img, heading, desc }: INewsCard) => {
    return (
        <Grid container className={styles.newsCard}>
            <Grid item sm={5}>
                <img src={img} />
            </Grid>
            <Grid item sm={7}>
                <Typography variant='h4' fontSize={21} fontWeight={600}>{heading}</Typography>
                <Box display={"flex"} marginBlock={1}>
                    <Box display={"flex"}>
                        <RiMapPinUserFill fontSize={21} style={{ marginBlock: "auto", color: "#44AE99" }} />
                        <Typography marginInlineStart={1} fontSize={15} sx={{ marginBlock: "auto" }}>David</Typography>
                    </Box>
                    <Box marginInline={2} display={"flex"}>
                        <FaRegCalendarAlt fontSize={18} style={{ marginBlock: "auto", color: "#44AE99" }} />
                        <Typography marginInlineStart={1} fontSize={15}>20 may 2024</Typography>
                    </Box>

                </Box>
                <Typography marginBlockStart={3} lineHeight={1.8}>{desc}</Typography>
                <CommonButton
                    name={'Learn more'}
                    color={'#ffffff'}
                    bgColor={'#1D2C36'}
                    handleClick={function (): void {
                        throw new Error('Function not implemented.');
                    }}
                />
            </Grid>

        </Grid>
    )
}

export default NewsCard