import React, { ReactNode } from 'react'
import styles from './HeadingText.module.scss'
import { Box, Grid, Typography } from '@mui/material'
import CommonButton from '../commonButton/CommonButton'
import { RiMapPinUserFill } from "react-icons/ri";

export interface IHeadingText {
    isLabel?: boolean;
    icon?: ReactNode;
    label?: string;
    color?:string;
    heading: string;
    span: string;
    desc?: string;
};

const HeadingText = ({ isLabel, icon, label,color, heading, span, desc }: IHeadingText) => {
    return (
        <Grid className={styles.headingTextContainer}>
            {isLabel ? <Box display={"flex"} marginBlockEnd={2}>
                <Box>{icon}</Box>
                <Typography variant='h4' fontSize={41} fontWeight={600} sx={{ color: color, marginInlineStart: 1 }} >{label}</Typography>
            </Box> : ""}
            <Typography variant='h4' fontWeight={600}>{heading}<span style={{ color: "#44AE99" }}>{span}</span> </Typography>
            <Typography marginBlock={2} lineHeight={1.8} fontSize={16}>{desc}</Typography>
        </Grid>
    )
}

export default HeadingText