import React from 'react'
import styles from './VisionAndMissions.module.scss'
import { Grid, Typography } from '@mui/material'
import HeadingText from '../headingText/HeadingText'
import img from '../../assest/images/3.png'
import img_1 from '../../assest/images/4.png'

const VisionAndMissions = () => {
    return (
        <Grid className={styles.visionAndMissionsContainer}>
            <HeadingText isLabel={true} label='' heading={'Our Mission and Vision'} span={''} />
            <Grid container className={styles.visionAndMissions}>
                <Grid item sm={6}>
                    <Typography variant='h4' fontSize={25} marginBlockEnd={2} fontWeight={600}>Mission</Typography>
                    <Typography>At Precilix Lifesciences, our mission is to advance global health through innovative research and exceptional healthcare solutions. We strive to provide high-quality products and services that drive breakthroughs in diagnostics, therapeutics, and preventive care. Our commitment to excellence, integrity, and collaboration ensures that we make a positive impact on the lives of patients and healthcare providers worldwide.</Typography>
                </Grid>
                <Grid item sm={5}>
                    <img src={img_1} />
                </Grid>
            </Grid>
            <Grid container className={styles.visionAndMissions}>
                <Grid item sm={5}>
                    <img src={img} />
                </Grid>
                <Grid item sm={6}>
                    <Typography variant='h4' fontSize={25} marginBlockEnd={2} fontWeight={600}>Vision</Typography>
                    <Typography>Our vision is to be a leading force in the healthcare industry, pioneering transformative solutions that shape the future. We aim to be recognized for our unwavering dedication to quality, sustainability, and ethical practices, creating a healthier world through cutting-edge research and innovative products.</Typography>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default VisionAndMissions