import React from 'react'
import styles from './ProductModal.module.scss'
import { Box, Divider, Grid, Modal, TextField, TextareaAutosize, Typography } from '@mui/material'
import { IoMdClose } from "react-icons/io";
import CommonButton from '../../commonButton/CommonButton';


export interface IProductModal {
    open: boolean,
    heading: string;
    inputVal: any;
    handleChange: any;
    handleSubmit: () => void
    handleClose: () => void;
}
const ProductModal = ({ open, heading, inputVal, handleChange, handleSubmit, handleClose }: IProductModal) => {
    return (
        <Modal
            open={open}
            className={styles.productModalContainer}
        >
            <Grid className={styles.productModal}>
                <Box sx={{ display: "flex", justifyContent: 'space-between' }}>
                    <Typography variant='h4' fontSize={21} fontWeight={500}>{heading}</Typography>
                    <IoMdClose fontSize={21} style={{ cursor: "pointer" }} onClick={handleClose} />
                </Box>
                <Divider sx={{ marginBlock: 2 }} />
                <Grid className={styles.field}>
                    <Typography>Product Name</Typography>
                    <TextField type='text' name={"name"} value={inputVal.name} onChange={handleChange} placeholder='Enter product name' />
                    <Typography marginBlockStart={1}>Product Image</Typography>
                    <TextField type='file' name={"image"} onChange={handleChange} placeholder='upload prduct image' />
                    <Typography marginBlockStart={1}>Product Description</Typography>
                    <TextareaAutosize minRows={6} name='desc' value={inputVal.desc} onChange={handleChange} />
                </Grid>
                <Divider sx={{ marginBlock: 2 }} />
                <Grid className={styles.action}>
                    <CommonButton name={'Cancel'} handleClick={handleClose} color={'#ffffff'} bgColor={'#444444'} />
                    <CommonButton name={'Submit'} handleClick={handleSubmit} color={'#ffffff'} bgColor={'#444444'} />
                </Grid>
            </Grid>
        </Modal>
    )
}

export default ProductModal