import React from 'react'
import styles from './InputField.module.scss'
import { Grid, TextField, Typography } from '@mui/material'

export interface IInputField {
    label: string;
    name: string;
    type?: any;
    value?: any;
    onChange: any;
    placeholder: string;

}
const InputField = ({ label, name, type, value, onChange, placeholder }: IInputField) => {
    return (
        <Grid className={styles.inputFieldContainer}>
            <Typography>{label}</Typography>
            <TextField name={name} value={value} type={type} onChange={onChange} placeholder={placeholder} />
        </Grid>
    )
}

export default InputField