import React from 'react'
import styles from './CommonButton.module.scss'
import { Button, Grid } from '@mui/material'

export interface ICommonButton {
  name: string;
  color: string
  bgColor: string;
  handleClick: () => void;
}
const CommonButton = ({ name, color, bgColor, handleClick }: ICommonButton) => {
  return (
    <Grid className={styles.commonButtonContainer}>
      <Button onClick={handleClick} sx={{ color: color, backgroundColor: bgColor, "&:hover": { color: color, backgroundColor: bgColor } }}>{name}</Button>
    </Grid>
  )
}

export default CommonButton