import React from 'react'
import styles from './Home.module.scss'
import { Grid, Typography } from '@mui/material'
import Banner from '../../components/banner/Banner'
import WhoWeAre from '../../components/WhoWeAre/WhoWeAre'
import OurClients from '../../components/OurClients/OurClients'
import TrustHolds from '../../components/TrustHolds/TrustHolds'
import OurProducts from '../../components/OurProducts/OurProducts'
import Testimonial from '../../components/Testimonial/Testimonial'
import ContactSection from '../../components/ContactSection/ContactSection'
import VisionAndMissions from '../../components/VisionAndMissions/VisionAndMissions'
export interface IHome {
    handleClick: () => void
}
const Home = ({ handleClick }: IHome) => {
    return (
        <Grid className={styles.homeContainer}>
            <Banner handleClick={handleClick} />
            <WhoWeAre />
            <VisionAndMissions />
            <OurProducts />
            {/* <OurClients /> */}
            {/* <Testimonial /> */}
            <ContactSection />
        </Grid>
    )
}

export default Home