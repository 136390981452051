import React, { useEffect, useState } from 'react'
import styles from './MolecularBiology.module.scss'
import { Box, Grid, Typography } from '@mui/material'
import HeadingText from '../../components/headingText/HeadingText'
import OurProducts from '../../components/OurProducts/OurProducts'
import Product from '../../components/Product/Product'
import { molecularBiology } from '../../data/home'
import CommonBanner from '../../components/CommonBanner/CommonBanner'
import Testimonial from '../../components/Testimonial/Testimonial'
import ContactSection from '../../components/ContactSection/ContactSection'
import axios from 'axios'
import { baseurl } from '../../baseurl/baseurl'

export interface IMolecularBiology {
    handleClick: () => void;
}
const MolecularBiology = ({ handleClick }: IMolecularBiology) => {
    const [productData, setProductData] = useState<any>()
    const getProductData = async () => {
        try {
            const response = await axios.get(`${baseurl}/sub-product`)

            if (response.status === 200) {
                const data = response.data.subProduct
                console.log(data, "data")
                const filteredData = data?.filter((item: any) => item?.productId?.name === "Molecular biology")
                console.log(filteredData, "filteredData")
                setProductData(filteredData)
            }
        }
        catch (err) {
            console.log(err)
        }
    };
    useEffect(() => {
        getProductData()
    }, [])
    return (
        <Grid className={styles.molecularBiologyContainer}>
            <CommonBanner heading={'Our Products'} />
            <Grid className={styles.molecularBiology}>
                <HeadingText
                    heading={'Molecular '}
                    span={'Biology'}
                    desc="Molecular biology investigates biological processes at the molecular level, focusing on DNA, RNA, and protein interactions within cells. It encompasses techniques like PCR, gene cloning, and sequencing to study genetic mechanisms, diseases, and evolutionary relationships, advancing our understanding of life's fundamental processes and applications in biotechnology and medicine."
                />
                <Grid className={styles.ourProducts}>
                    {productData && productData.length > 0 && productData.map((item: any) => {
                        return (
                            <Grid className={styles.products}>
                                <Product
                                    img={item.image}
                                    name={item.name}
                                    desc={item.desc}
                                    handleClick={handleClick}
                                />
                            </Grid>
                        )
                    })}
                </Grid>
            </Grid>
            
            {/* <Testimonial/> */}
            <ContactSection />
        </Grid>
    )
}

export default MolecularBiology