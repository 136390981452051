import React from 'react'
import styles from './QueryNow.module.scss'
import { Box, Divider, Grid, Modal, Typography } from '@mui/material'
import CommonButton from '../../commonButton/CommonButton';
import InputField from '../../InputField/InputField';
import { IoMdClose } from "react-icons/io";


export interface IQueryNow {
    open: boolean;
    inputVal:any;
    handleClose: any;
    handleChange:any;
    handleSubmit:()=>void;
}
const QueryNow = ({ open, inputVal, handleClose,handleChange,handleSubmit }: IQueryNow) => {
    return (
        <Modal
            open={open}
            className={styles.queryNowContainer}
        >
            <Grid className={styles.queryNow}>
                <Box display={"flex"} justifyContent={"space-between"}>
                    <Typography variant='h5' fontSize={21} fontWeight={500}>Enquiry</Typography>
                    <IoMdClose fontSize={21} style={{ cursor: "pointer" }} onClick={handleClose} />
                </Box>
                <Divider sx={{ marginBlock: 2, background:"#ffffff" }} />
                <Grid className={styles.fields}>
                    <InputField label={'Name'} name={'name'} value={inputVal.name} onChange={handleChange} placeholder={''} />
                    <InputField label={'Email'} name={'email'} value={inputVal.email} onChange={handleChange} placeholder={''} />
                    <InputField label={'Phone'} name={'phone'} value={inputVal.phone} onChange={handleChange} placeholder={''} />
                    <InputField label={'Message'} name={'message'} value={inputVal.message} onChange={handleChange} placeholder={''} />
                </Grid>
                <Divider sx={{ marginBlock: 2, background:"#ffffff" }} />
                <Grid className={styles.action}>
                    <CommonButton name={'Cancel'} color={'#ffffff'} bgColor={'#1D2A35'} handleClick={handleClose} />
                    <CommonButton name={'Submit'} color={'#ffffff'} bgColor={'#1D2A35'} handleClick={handleSubmit} />

                </Grid>
            </Grid>
        </Modal>
    )
}

export default QueryNow