import React from 'react'
import styles from './NewsSection.module.scss'
import { Box, Divider, Grid, Typography } from '@mui/material'
import HeadingText from '../headingText/HeadingText'
import { newsData } from '../../data/home'
import NewsCard from '../NewsCard/NewsCard'
const NewsSection = () => {
    return (
        <Grid className={styles.newsSectionContainer}>
            <Grid>
                <HeadingText
                    heading={'Latest '}
                    span={'News'}
                    desc='Lorem ipsum dolor sit amet, consectetur adipisicing elit. Consequuntur ex voluptatibus repellendus eum pariatur dolor labore, omnis, quis suscipit fuga accusantium, commodi quaerat ut sunt officia fugiat! Quia, animi reiciendis?'
                />
                
            </Grid>
            <Grid className={styles.newsSection}>
                {newsData.map((item) => {
                    return (
                        <Box>
                            <NewsCard img={item.image} heading={item.heading} desc={item.desc} />
                            <Divider sx={{ marginBlock: 4 }} />
                        </Box>
                    )
                })}
            </Grid>
        </Grid>
    )
}

export default NewsSection