import { Grid } from '@mui/material'
import React from 'react'
import CommonBanner from '../../components/CommonBanner/CommonBanner'
import CompanyOverview from '../../components/CompanyOverview/CompanyOverview'
import ContactSection from '../../components/ContactSection/ContactSection'
import OurTeam from '../../components/OurTeam/OurTeam'
import OurPeople from '../../components/OurPeople/OurPeople'

const About = () => {
    return (
        <Grid>
            <CommonBanner heading={'About Us'} />
            <CompanyOverview />
            {/* <OurPeople /> */}
            <ContactSection />
        </Grid>
    )
}

export default About