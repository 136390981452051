import React, { useEffect, useState } from 'react'
import styles from './OurProducts.module.scss'
import { Box, Grid, Typography } from '@mui/material'
import HeadingText from '../headingText/HeadingText'
import { FaStop } from "react-icons/fa6";
import Product from '../Product/Product';
// import { productData } from '../../data/home'
import QueryNow from '../modal/QueryNow/QueryNow';
import axios from 'axios';
import { baseurl } from '../../baseurl/baseurl';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const OurProducts = () => {
    const [open, setOpen] = useState(false)
    const handleClose = () => setOpen(false)
    const [productData, setProductData] = useState<any>({})
    const [inputVal, setInputVal] = useState({ name: "", email: "", phone: "", message: "" })
    const handleClick = (name: any) => {
        setOpen(!open)
    }
    const getProdctData = async () => {
        try {
            const response = await axios.get(`${baseurl}/product`)
            console.log(response, "response..")
            if (response.status === 200) {
                setProductData(response.data.product)

            }
        }
        catch (err) {
            console.log(err)
        }
    }
    const handleChange = (e: any) => {
        const { name, value } = e.target;
        setInputVal({ ...inputVal, [name]: value })
    }

    const handleSubmit = async () => {
        if (inputVal.name === "" || inputVal.email === "" || inputVal.phone === "" || inputVal.message === "") {
            return toast.error("Please fill all the field")
        }
        try {
            const response = await axios.post(`${baseurl}/enquiry/create`, inputVal)
            console.log(response, "response")
            if (response.status === 201) {
                toast.success(response.data.message)
                setOpen(false)
                setInputVal({name: "", email: "", phone: "", message: ""})
                getProdctData()
            }

        }
        catch (err) {
            console.log(err)
        }

    }
    useEffect(() => {
        getProdctData();
    }, []);

    return (
        <Grid className={styles.ourProductsContainer}>
            <Grid>
                <HeadingText
                    isLabel={false}
                    icon={<FaStop style={{ color: "#44AE99" }} />}
                    label='Top'
                    heading={'Our '}
                    span={'Products'}
                    desc='Explore our range of groundbreaking precision healthcare products designed to elevate patient care and drive advancements in medical practices.'
                />
            </Grid>
            <Grid className={styles.ourProducts}>
                {productData && productData.length > 0 && productData?.map((item: any) => {
                    return (
                        <Box>
                            <Product
                                img={item.image}
                                name={item.name}
                                desc={item.desc}
                                handleClick={() => handleClick(item.id)}
                            />
                        </Box>
                    )
                })}
            </Grid>
            <QueryNow
                open={open}
                inputVal={inputVal}
                handleSubmit={handleSubmit}
                handleChange={handleChange}
                handleClose={handleClose}
            />
            <ToastContainer />
        </Grid>
    )
}

export default OurProducts