import React from 'react'
import styles from './ContactSection.module.scss'
import { Box, Grid, Typography } from '@mui/material'
import Contact from '../../pages/contact/Contact'

const ContactSection = () => {
    return (
        <Grid className={styles.contactSection}>
            <Contact/>
        </Grid>

    )
}

export default ContactSection